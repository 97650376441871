import React, { PropsWithChildren } from 'react';

import classes from './register-layout.module.css';

export interface RegisterLayoutProps {}

export const RegisterLayout: React.FC<PropsWithChildren<RegisterLayoutProps>> = ({
  children,
}) => {
  return (
    
     <main className={classes.main}>
      <header>
        <img src="/image/vascular-logo.png" alt="vascular-logo" />
      </header>
      <section className={classes.content}>
        <div className="w-100">{children}</div>
      </section>
    </main>
    
  );
};
