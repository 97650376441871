import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
    CognitoUserAttribute,
    ISignUpResult
} from 'amazon-cognito-identity-js';

import { COGNITO_USER_POOL_ID, COGNITO_CLIENT_ID } from '../constants';

export default class User {
    constructor(
        private username: string,
        private password: string,
        private company?: string,
    ) {
        this.username = username;
        this.password = password;
        this.company = company;
    }

    login(): Promise<string> {
        const authenticationData = {
            Username: this.username,
            Password: this.password,
        };
        const authenticationDetails = new AuthenticationDetails(
            authenticationData
        );

        const poolData = {
            UserPoolId: COGNITO_USER_POOL_ID,
            ClientId: COGNITO_CLIENT_ID,
        };

        const userPool = new CognitoUserPool(poolData);

        const userData = {
            Username: this.username,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    const accessToken = result.getAccessToken().getJwtToken();
                    resolve(accessToken);
                },
                onFailure: function (err) {
                    reject(err);
                },
            });
        })
    }

    register(): Promise<ISignUpResult> {
        const dataCompany = {
            Name: 'custom:company',
            Value: this.company || ''
        };

        const attributeCompany = new CognitoUserAttribute(dataCompany);

        const dataEmail = {
            Name: 'email',
            Value: this.username
        };
        const attributeEmail= new CognitoUserAttribute(dataEmail);

        const attributeList = [attributeCompany, attributeEmail];

        const poolData = {
            UserPoolId: COGNITO_USER_POOL_ID,
            ClientId: COGNITO_CLIENT_ID,
        };

        const userPool = new CognitoUserPool(poolData);

        return new Promise((resolve, reject) => {
            userPool.signUp(this.username, this.password, attributeList, null!, (err, result) => {
                if (err) {
                    reject(err);
                } 
                resolve(result!);
            });
        })
    }
}