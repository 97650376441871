import React, { PropsWithChildren, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { RegisterLayout } from '../layouts/register-layout';
import classes from './register-page.module.css';

import User from '../api/user';
import useStepStore from '../stores/step-store';

import { STEPS } from '../constants';


export interface RegisterUser {
  company: string;
  email: string;
  password: string;
}

export interface RegisterPageProps { }
export const RegisterPage: React.FC<PropsWithChildren<RegisterPageProps>> = () => {
  const formik = useFormik<RegisterUser>({
    initialValues: {
      company: '',
      email: '',
      password: ''
    },
    validationSchema: yup.object({
      company: yup.string().required('Required'),
      email: yup.string().email("Invalid email address").required(""),
      password: yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(
          /^(?=.*[a-z])(?=.*\d)[A-Za-z\d]{6,}$/,
          "Password must contain at least one lowercase letter, and one number"
        )
        .required("")
    }),
    onSubmit: async (userForm) => {
      const user = new User(userForm.email, userForm.password, userForm.company);
      try {
        await user.register();
        setStep(STEPS.LOGIN);
      } catch (e: any) {
        if (e.code === "UsernameExistsException") {
          setRegisterError("User already exists");
        } else if(e.code === "InvalidPasswordException") {
          setRegisterError("Password must contain at least one lowercase letter, one number and one special character");
          
        }
      }
    }
  });
  const { setStep } = useStepStore((state) => state);

  const [registerError, setRegisterError] = useState("");

  return (
    <RegisterLayout>
      <div className='registerForm'>
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className={classes.inputGroup}>
            <label htmlFor="name">Company Name</label>
            <input type="text" id="company" {...formik.getFieldProps("company")} />
            {formik.touched.company && formik.errors.company && (
              <div>{formik.errors.company}</div>
            )}
          </div>

          <div className={classes.inputGroup}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" {...formik.getFieldProps("email")} />
            {formik.touched.email && formik.errors.email && (
              <div>{formik.errors.email}</div>
            )}
          </div>

          <div className={classes.inputGroup}>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" {...formik.getFieldProps("password")} />
            {formik.touched.password && formik.errors.password && (
              <div>{formik.errors.password}</div>
            )}
          </div>

          <button className={classes.registerBtn} type="submit">Register</button>
          {registerError && <div className="error">{registerError}</div>}
        </form>
      </div>

    </RegisterLayout>
  );
};

