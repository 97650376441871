import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Panel } from '@salesforce/design-system-react';
import Autocomplete from 'react-autocomplete';
import ReactJSON from 'react-json-view';
import classnames from 'classnames';
import { useFormik } from 'formik';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import * as yup from 'yup';
import { MessageData } from 'vascular-js';

import { handleContentfulLogin, useContent, useEnvironments, useSpaces } from '../api/contentful';
import { useActivity, useConnection } from '../providers/connection-provider';
import { transformMessageModel } from '../util/message';

import { MainLayout } from '../layouts/main-layout';

import classes from './home-page.module.css';

export interface HomePageProps { }

export const HomePage: React.FC<PropsWithChildren<HomePageProps>> = () => {
  const connection = useConnection();
  const activity = useActivity();
  const [message, setMessage] = useState<any>(null);
  // const secrets = useUserStore();
  const [messageSource, setMessageSource] = useState("form");
  const [accessToken, setAccessToken] = useState<string | null>(null);

  console.log('Activity', activity);
  console.log('connection', connection);

  const updateMessage = (message: any, contentful: boolean) => {
    if (contentful) {
      setMessage(transformMessageModel(message));
    } else {
      setMessage([message]);
    }
  };

  useEffect(() => {
    connection?.trigger('updateButton', {
      button: 'next',
      text: 'Save',
    });
  }, [connection]);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const token = params.get('access_token');
      if (token) {
        setAccessToken(token);
        // Optionally, you could clear the hash from the URL:
        window.history.replaceState(null, '', window.location.pathname);

        // 2️⃣ Send token back to SFMC main window if this is a popup
        if (window.opener) {
          window.opener.postMessage({ accessToken: token }, "*");
          window.close(); // Close the popup after sending token
        }

      }
    }
    const handleSave = () => {
      const payload = { metaData: {}, arguments: { execute: {} }, ...activity };
      console.log('payload', JSON.stringify(payload));
      payload.metaData.isConfigured = true;
      payload.arguments.execute.inArguments = [
        {
          data: {
            message,
          },
        },
      ];

      console.log('Will save ', JSON.stringify(payload));
      localStorage.removeItem('activity');
      connection?.trigger('updateActivity', payload);
    };

    connection?.on('clickedNext', handleSave);
    return () => connection?.off('clickedNext', handleSave);
  }, [connection, message, activity]);

  // 3️⃣ Listen for messages from OAuth popup (if running inside SFMC)
  useEffect(() => {
    function handleMessage(event: any) {
      if (event.data && event.data.accessToken) {
        setAccessToken(event.data.accessToken);
        console.log("Received Access Token from popup:", event.data.accessToken);
      }
    }

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (messageSource !== 'form' && !accessToken) {
      handleContentfulLogin();
    }
  }, [messageSource, accessToken]);

  // for development
  // const test = () => {
  //   console.log("The message sent to handling api: ", message);
  // }

  return (
    <MainLayout>
      <div className={classes.container}>
        <div>
          <button className={classes.optionBtn} onClick={() => setMessageSource('form')}>Form</button>
          <button className={classes.optionBtn} onClick={() => setMessageSource('contentful')}>Contentful</button>
        </div>
        {messageSource === 'form' ? <MessageForm updateMessage={updateMessage} /> : <MessageContentful updateMessage={updateMessage} accessToken={accessToken} />}
      </div>
      {/* {process.env.NODE_ENV === 'development' ? <button onClick={test}>Test</button> : null} */}
    </MainLayout>
  );
};


function MessageForm({ updateMessage }: any) {
  const formik = useFormik<MessageData>({
    initialValues: {
      title: '',
      subTitle: '',
      body: '',
      media: {
        thumbnail: '',
        image: ''
      },
      actions: [{
        name: '',
        value: ''
      }],
      language: 'enUs',
      metadata: ''
    },
    validationSchema: yup.object({
      title: yup.string().required('Required'),
      body: yup.string().required('Required'),
      'media.thumbnail': yup.string().url(),
      'media.image': yup.string().url(),
    }),
    onSubmit: (message) => {
      updateMessage(message, false);
    }
  })

  const handleActionAddtion = () => {
    formik.setFieldValue("actions", [...formik.values.actions, {
      key: '',
      value: ''
    }])
  }

  const handleActionRemove = (id: number) => {
    const actions = formik.values.actions.filter((action: any, index: any) => index !== id);
    formik.setFieldValue("actions", [...actions]);
  }

  return (
    <div className={classes.containerContent}>
      <Panel className={classnames(classes.panel, classes.selectionPanel)}>
        <form className="form">
          <div className={classes.inputGroup}>
            <label className={classes.required}>Title</label>
            <input type="text" id="title" {...formik.getFieldProps("title")} placeholder="Title" />
            {formik.errors.title && formik.touched.title ? (
              <div>Required</div>
            ) : null}
          </div>
          <div className={classes.inputGroup}>
            <label>Sub title</label>
            <input type="text" id="subTitle" {...formik.getFieldProps("subTitle")} placeholder="Sub title" />
          </div>
          <div className={classes.inputGroup}>
            <label className={classes.required}>Body</label>
            <textarea id="body" {...formik.getFieldProps("body")} placeholder="Body" />
            {formik.errors.body && formik.touched.body ? (
              <div>Required</div>
            ) : null}
          </div>
          <div className={classes.inputGroup}>
            <label>Media</label>
            <input type="text" id="thumbnail" {...formik.getFieldProps("media.thumbnail")} placeholder="Thumbnail" />
            <input type="text" id="image" {...formik.getFieldProps("media.image")} placeholder="Image" />
          </div>
          <div className={classes.inputGroup}>
            <label className={classes.required}>Language</label>
            <select {...formik.getFieldProps("language")}>
              <option value="enUs">enUs</option>
              <option value="enUk">enUk</option>
              <option value="nb">nb</option>
            </select>
          </div>
          <div className={classes.inputGroup}>
            <label>Actions</label>
            <button type="button" className={classes.addActionBtn} onClick={handleActionAddtion}>Add action</button>
            {formik.values.actions.map((action: any, i: number) => {
              return (
                <div className={classes.inputActionGroup} key={i}>
                  <IconButton onClick={() => handleActionRemove(i)} color="error" aria-label="remove" component="label">
                    <Cancel />
                  </IconButton>
                  <input type="text" placeholder="key" value={action.key} onChange={(event: any) => formik.setFieldValue(`actions.${i}.key`, event.target.value)} />
                  <input type="text" placeholder="value" value={action.value} onChange={(event: any) => formik.setFieldValue(`actions.${i}.value`, event.target.value)} />
                </div>
              )
            })}
          </div>
          <div className={classes.inputGroup}>
            <label>Metadata</label>
            <textarea id="metadata" {...formik.getFieldProps("metadata")} placeholder="Metadata" />
          </div>
          <button type="button" onClick={formik.submitForm} className={classes.addActionBtn}> Save</button>
        </form>
      </Panel>
      <MessagePreview message={formik.values} />
    </div>
  )
}

function MessageContentful({ updateMessage, accessToken }: any) {
  const [space, setSpace] = useState('');
  const [environment, setEnvironment] = useState('');
  const [message, setMessage] = useState<any>(null);
  const [query, setQuery] = useState('');

  const { data: spaces } = useSpaces(accessToken);
  const { data: environments } = useEnvironments(space, accessToken);
  const { data: messages } = useContent(space, environment, accessToken, query);

  const handleSelectSpace = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSpace(e.target.value);

  const handleSelectEnvironment = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setEnvironment(e.target.value);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value);

  const handleSelectMessage = (_val: any, message: any) => {
    updateMessage(message, true);
    setMessage(message);
    setQuery(message.title['en-US']);
  };
  return (
    <div className={classes.containerContent}>
      <Panel className={classnames(classes.panel, classes.selectionPanel)}>
        <div>
          <label htmlFor="space">Space</label>
          <select
            id="space"
            className="slds-select"
            name="space"
            value={space}
            onChange={handleSelectSpace}
          >
            <option value="">Select space</option>
            {spaces?.map((space) => (
              <option key={space.id} value={space.id}>
                {space.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.formControl}>
          <label htmlFor="environment">Environment</label>
          <select
            id="environment"
            className="slds-select"
            name="environment"
            value={environment}
            onChange={handleSelectEnvironment}
            disabled={!environments?.length}
          >
            <option value="">Select environment</option>
            {environments?.map((environment) => (
              <option key={environment.id} value={environment.id}>
                {environment.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.formControl}>
          <label htmlFor="message">Message</label>
          <br />
          <Autocomplete
            getItemValue={(item) => item.id}
            items={messages ?? []}
            renderItem={(item, isHighlighted) => (
              <div
                className={classes.messageItem}
                style={{
                  backgroundColor: isHighlighted
                    ? 'lightgray'
                    : 'transparent',
                }}
              >
                {item.title['en-US']}
              </div>
            )}
            value={query}
            onChange={handleQueryChange}
            onSelect={handleSelectMessage}
            wrapperStyle={{
              width: '100%',
            }}
            inputProps={{
              className: 'slds-input w-100',
            }}
          />
        </div>
      </Panel>
      <MessagePreview message={message} />
    </div>
  )
}

function MessagePreview(message: any) {

  const msg = message.message || message;

  const languages = msg && msg.title ? Object.keys(msg.title) : ['en-US'];
  const [selectedLang, setSelectedLang] = useState(languages[0]);

  const [view, setView] = useState<'json' | 'ui'>('json');
  const renderUIPreview = () => (
    <div className={classes.messageCard}>
      <div className={classes.cardHeader}>
        <h2 className={classes.cardTitle}>{msg.title?.[selectedLang]}</h2>
      </div>
      <div className={classes.cardBody}>
        <p>{msg.body?.[selectedLang]}</p>
        {msg.media &&
          msg.media[selectedLang] &&
          msg.media[selectedLang].image && (
            <img
              src={msg.media[selectedLang].image}
              alt={msg.title?.[selectedLang]}
              className={classes.cardImage}
            />
          )}
      </div>
      {msg.actions &&
        msg.actions[selectedLang] &&
        Array.isArray(msg.actions[selectedLang]) && (
          <div className={classes.cardFooter}>
            {msg.actions[selectedLang].map((action: any, index: number) => {
              const buttonLabel = action.name?.[selectedLang] || 'Action';
              const buttonUrl = action.value?.[selectedLang];
              return (
                <button
                  key={index}
                  className={classes.actionButton}
                  onClick={() => {
                    if (buttonUrl) window.open(buttonUrl, '_blank');
                  }}
                >
                  {buttonLabel}
                </button>
              );
            })}
          </div>
        )}
    </div>

  );
  return (
    <Panel className={classnames(classes.panel, classes.previewPanel)}>
      <div className={classes.formControl}>
        <div className={classes.toggleContainer}>
          <button
            type="button"
            onClick={() => setView('json')}
            className={view === 'json' ? classes.active : ''}
          >
            JSON Preview
          </button>
          <button
            type="button"
            onClick={() => setView('ui')}
            className={view === 'ui' ? classes.active : ''}
          >
            UI Preview
          </button>
        </div>

        {view === 'ui' && (
          <div className={classes.languageSelector}>
            <label htmlFor="languageSelect">
              <strong>Select Language:</strong>{' '}
            </label>
            <select
              id="languageSelect"
              value={selectedLang}
              onChange={(e) => setSelectedLang(e.target.value)}
            >
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
        )}

        <br />

        {view === 'json' ? (
          <ReactJSON
            src={message}
            collapsed
            style={{ backgroundColor: '#f8f8f8', padding: '1rem' }}
          />
        ) : (
          renderUIPreview()
        )}
      </div>
    </Panel>
  );
}


