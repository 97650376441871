export enum STEPS {
  REGISTER ='register-step',
  LOGIN = 'login-step',
  MAIN = 'message-step',
}

export const CONTENTFUL_API_URL = 'https://api.contentful.com';
export const CONTENTFUL_CLIENT_ID = process.env.REACT_APP_CONTENTFUL_CLIENT_ID;
export const CONTENTFUL_OAUTH_REDIRECT_URL =
  process.env.REACT_APP_CONTENTFUL_OAUTH_REDIRECT_URL || "";

export const AUTH_LAMBDA_URL =
  process.env.REACT_APP_AUTH_LAMBDA_URL || 'http://localhost:7000';

// Azure SSO
export const SSO_ENABLED = false;
export const AZURE_AUTH_URL = process.env.REACT_APP_CLOUD_INSTANCE;
export const AZURE_TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const AZURE_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const OAUTH_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URI;

export const SSO_INFO = 'http://localhost:3000/soo_info'

// AUTH COGNITO
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || '';
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || '';
