import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { CONTENTFUL_API_URL, CONTENTFUL_CLIENT_ID, CONTENTFUL_OAUTH_REDIRECT_URL } from '../constants';

export const contentfulApi = axios.create({
  baseURL: CONTENTFUL_API_URL
});

function mapCommonResponse(items: any[]) {
  return items.map((item) => ({
    id: item.sys.id,
    name: item.name,
  }));
}

function mapItemsResponse(items: any) {
  return items.map((item: any) => {
    const result: Record<string, any> = { id: item.sys.id };
    Object.keys(item.fields).forEach((key) => {
      result[key] = item.fields[key];
    });
    return result;
  });
}

export async function getSpaces(accessToken: string) {
  const { data } = await contentfulApi.get('/spaces', {
    params: {
      access_token: accessToken,
    },
  });
  return mapCommonResponse(data.items);
}

export function useSpaces(accessToken: string) {
  return useQuery(['GET_SPACES', accessToken], () => getSpaces(accessToken));
}

export async function getSpaceEnvironments(space: string, accessToken: string) {
  const { data } = await contentfulApi.get(`/spaces/${space}/environments`, {
    params: {
      access_token: accessToken,
    },
  });
  return mapCommonResponse(data.items);
}

export function useEnvironments(space: string, accessToken: string) {
  return useQuery(
    ['GET_ENVIRONMENTS', space],
    () => getSpaceEnvironments(space, accessToken),
    {
      enabled: Boolean(space),
    }
  );
}

export async function getContent(
  space: string,
  environment: string,
  accessToken: string,
  query: string
) {
  let url = `/spaces/${space}/environments/${environment}/entries`;
  url += `?access_token=${accessToken}`;
  url += '&limit=10';
  url += '&select=sys.id,fields';
  url += query ? `&query=${query}` : '';
  const { data } = await contentfulApi.get(url);
  return mapItemsResponse(data.items);
}

export function useContent(space: string, environment: string, accessToken: string, query: string) {
  return useQuery(
    ['GET_CONTENT', space, environment, accessToken, query],
    () => getContent(space, environment, accessToken, query),
    {
      enabled: Boolean(space) && Boolean(environment),
    }
  );
}

export function handleContentfulLogin() {
  const clientId = CONTENTFUL_CLIENT_ID;
  const redirectUri = CONTENTFUL_OAUTH_REDIRECT_URL; // Must match your registered redirect URI in Contentful
  const scope = 'content_management_read';

  // Construct the OAuth URL for Contentful.
  const authUrl = `https://be.contentful.com/oauth/authorize?response_type=token&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scope}`;

  // Redirect the user to Contentful's OAuth endpoint.
  window.open(authUrl, "_blank", "width=600,height=600");
};